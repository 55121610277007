<template>
  <manger-page-header
    v-model:value="btnIndex"
    :btnList="btnList"
    @changeIndex="changeIndex"
  >
    <a-input
      placeholder="搜索工序卡id、编号、名称、设备"
      v-model:value="search"
      @keyup.enter="fetchSearch"
    ></a-input>
  </manger-page-header>
  <div v-if="btnIndex == 0">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      class="table"
      rowKey="id"
      @change="changePage"
    >
      <template #action1="{ record }">
        <a-button type="link" size="small" @click="goEditProgress(record.id)"
          >编辑</a-button
        >
        <a-button
          type="link"
          size="small"
          class="col-red"
          @click="fetchDeleteProcess(record.id)"
          >删除</a-button
        >
      </template>
    </a-table>
  </div>
  <!-- 新建工序卡 start -->
  <create-process v-if="btnIndex == 1" @changeIndex="changeIndex"></create-process>
  <!-- 新建工序卡 end -->
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import mangerPageHeader from '@/components/btn-and-search.vue'
import { btnAndSearchHooks } from '@/hooks/btn-and-search-hooks'
import { getProcessList, deleteProcess } from '@/api/index'
import { showConfirm, setPage } from '@/hooks/common-hooks'
import createProcess from '@/views/processManger/components/createProcess'
import { useRouter } from 'vue-router'
// import '@/mock/index'
const btnList = [
  {
    id: 1,
    btnName: '工序卡列表'
  },
  {
    id: 2,
    btnName: '新建工序卡'
  }
]
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 60
  },
  {
    title: '编号',
    dataIndex: 'process_num',
    key: 'process_num',
    width: 120
  },
  {
    title: '名称',
    dataIndex: 'process',
    key: 'process'
  },
  {
    title: '设备',
    dataIndex: 'device',
    key: 'device'
  },
  {
    title: '描述',
    dataIndex: 'content',
    key: 'content'
  },
  {
    title: '',
    key: 'action',
    slots: { customRender: 'action1' },
    align: 'right'
  }
]
const pagination = {
  current: 1,
  pageSize: 10,
  total: 0
}
export default defineComponent({
  name: 'ProcessManger',
  components: {
    mangerPageHeader,
    createProcess
  },
  setup() {
    const router = useRouter()
    const tableData = reactive({
      columns,
      data: [],
      search: '',
      pagination
    })
    // 获取列表
    const fetchGetProcessList = isDelete => {
      getProcessList({
        search: tableData.search,
        page: tableData.pagination.current,
        pagesize: tableData.pagination.pageSize
      }).then(res => {
        tableData.data = res.data
        tableData.pagination.total = res.result
        if (isDelete && tableData.data.length === 0) {
          tableData.pagination.current = setPage(tableData.pagination)
          fetchGetProcessList()
        }
      })
    }
    const { changeIndex, btnIndex } = btnAndSearchHooks(fetchGetProcessList)
    // 搜索列表
    const fetchSearch = () => {
      tableData.pagination.current = 1
      fetchGetProcessList()
    }
    // 翻页
    const changePage = pagination => {
      tableData.pagination.current = pagination.current
      fetchGetProcessList()
    }
    // 删除
    const fetchDeleteProcess = id => {
      showConfirm({
        content: '此操作不可逆，确定要删除该工序卡吗?',
        callBack: () => {
          deleteProcess({ id }).then(() => {
            fetchGetProcessList(true)
          })
        }
      })
    }
    // 编辑
    const goEditProgress = id => {
      router.push({ name: 'EditProcess', params: { id } })
    }

    onMounted(() => {
      fetchGetProcessList()
    })
    return {
      btnList,
      btnIndex,
      changeIndex,
      fetchSearch,
      changePage,
      ...toRefs(tableData),
      fetchDeleteProcess,
      goEditProgress
    }
  }
})
</script>

<style>
</style>
