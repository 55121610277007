<template>
  <div class="top-box" v-if="processId">
    <span class="inline"></span>
    <span class="text">工序卡-编辑</span>
  </div>
  <a-alert
    v-if="processId"
    message="该页面操作不是实时保存，修改数据后记得点击保存按钮保存数据！"
    banner
    closable
  />
  <a-form
    class="myForm box-shadow"
    :label-col="{ span: 3 }"
    :wrapper-col="{ span: 20 }"
    :model="formState"
    :rules="rules"
    ref="formRef"
  >
    <a-form-item label="工序卡名称:" name="process">
      <a-input
        placeholder="请输入工序卡名称"
        :maxlength="100"
        autocomplete="off"
        v-model:value="formState.process"
      />
    </a-form-item>
    <a-form-item label="工序卡编号:" name="process_num">
      <a-input
        placeholder="请输入工序卡编号"
        :maxlength="20"
        autocomplete="off"
        v-model:value="formState.process_num"
      />
    </a-form-item>
    <a-form-item label="设备名称:" name="device">
      <a-input
        placeholder="请输入设备名称"
        :maxlength="20"
        autocomplete="off"
        v-model:value="formState.device"
      />
    </a-form-item>
    <a-form-item label="描述:">
      <a-textarea
        placeholder="请输入描述"
        :maxlength="200"
        autocomplete="off"
        v-model:value="formState.content"
        :rows="4"
      />
    </a-form-item>
    <a-form-item label="操作项:">
      <operation-list-parent
        ref="operationRef"
        :data="formState.items"
      ></operation-list-parent>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 3, offset: 3 }" v-if="!disabled">
      <a-button
        type="primary"
        htmlType="submit"
        @click.prevent="handleCreateProcess"
      >
        提交
      </a-button>
    </a-form-item>
    <a-form-item :wrapper-col="{ span: 3, offset: 3 }" v-else>
      <a-button
        type="primary"
        htmlType="submit"
        @click.prevent="handleEditProcess"
      >
        保存
      </a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { defineComponent, reactive, computed, ref, onMounted } from 'vue'
import operationListParent from '@/views/processManger/components/operationListParent.vue'
import { createProcess, getProcessDes, editProcess } from '@/api'
import { message } from 'ant-design-vue'
import { filterKeys } from '@/hooks/common-hooks'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'EditProcess',
  props: { id: [String, Number] },
  components: { operationListParent },
  setup(props, ctx) {
    const router = useRouter()
    const operationRef = ref()
    const formRef = ref()
    const formState = reactive({
      process_num: '',
      process: '',
      device: '',
      content: '',
      items: []
    })
    const processId = computed(() => props.id)
    const disabled = computed(() => {
      if (processId.value) {
        return true
      } else {
        return false
      }
    })
    const rules = {
      process_num: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ],
      process: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ],
      device: [
        {
          required: true,
          trigger: 'blur',
          whitespace: true
        }
      ]
    }
    // 创建工序卡
    const handleCreateProcess = () => {
      formRef.value.validate().then(() => {
        if (
          operationRef.value.getList().length > 0 &&
          !operationRef.value.handleCheckNode()
        ) {
          return false
        }
        createProcess(
          Object.assign(formState, {
            items: operationRef.value.getList()
          })
        ).then(() => {
          message.success('创建成功')
          ctx.emit('changeIndex', 0)
        })
      })
    }
    // 编辑工序卡
    const handleEditProcess = () => {
      formRef.value.validate().then(() => {
        if (
          operationRef.value.getList().length > 0 &&
          !operationRef.value.handleCheckNode()
        ) {
          return false
        }
        editProcess(
          Object.assign(formState, {
            process_id: processId.value,
            items: operationRef.value.getList([
              'id',
              'content',
              'file_paths',
              'items'
            ])
          })
        ).then(() => {
          message.success('编辑成功')
          // router.replace({ name: 'ProcessManger' })
        })
      })
    }
    // 编辑页面时,初始化详情页
    const initData = () => {
      getProcessDes({
        id: props.id
      }).then(res => {
        filterKeys(formState, res.data)
      })
    }
    onMounted(() => {
      if (props.id) {
        initData()
      }
    })
    return {
      formState,
      disabled,
      processId,
      rules,
      handleCreateProcess,
      operationRef,
      formRef,
      handleEditProcess
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/scss/theme.scss';
@import '@/scss/form.scss';
</style>
